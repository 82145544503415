import React, { useEffect, useState } from "react";

import "./App.css";
import "../src/comman/index.css";
import "./pages/index.css";
import "../src/index.css";
import store from "./redux/store";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import theme from "./theme";
import { ThemeProvider } from "@mui/material";
import AppRoutes from "./routes";
import LoaderContext from "./context/LoaderContext";
import AwsAmplifyInit from "./http/services/aws-amplify";
import { AuthContext, useAuth } from "./context/AuthContext";
import { SnackbarProvider, closeSnackbar } from "notistack";
import NotificationContext from "./context/NotificationContext";
import { AxiosInterceptorContextProvider } from "./context/AxiosInterceptor";
// import '../src/pages/insurance/mockApi/insuranceMock';
const App = () => {
  AwsAmplifyInit();
  const [isLoader, setIsLoader] = useState(false);
  const [notificationCount, setNotificationCount] = useState(0);
  const [authUser, setAuthUser] = useState(
    localStorage.getItem("authUser") &&
      localStorage.getItem("authUserProfile") &&
      localStorage.getItem("authUserValidated")
      ? {
          ...JSON.parse(localStorage.getItem("authUser")),
          authUserProfile: {
            ...JSON.parse(localStorage.getItem("authUserProfile")),
            is_vault_pin_enter:
              localStorage.getItem("enterVaultPin") === "true" ? true : false,
          },
          authUserValidated:
            localStorage.getItem("authUserValidated") === "false"
              ? false
              : true,
        }
      : null
  );
  const { startAutoLogoutTimer, sessionAutoLogoutTimerClear } = useAuth();

  useEffect(() => {
    startAutoLogoutTimer();
    return () => sessionAutoLogoutTimerClear();
  }, []);

  return (
    <Router>
      <Provider store={store}>
        <AxiosInterceptorContextProvider>
          <NotificationContext.Provider
            value={{ notificationCount, setNotificationCount }}
          >
            <AuthContext.Provider value={{ authUser, setAuthUser }}>
              <LoaderContext.Provider value={{ isLoader, setIsLoader }}>
                <SnackbarProvider
                  maxSnack={3}
                  autoHideDuration={3000}
                  anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                >
                  <ThemeProvider theme={theme}>
                    <AppRoutes />
                  </ThemeProvider>
                </SnackbarProvider>
              </LoaderContext.Provider>
            </AuthContext.Provider>
          </NotificationContext.Provider>
        </AxiosInterceptorContextProvider>
      </Provider>
    </Router>
  );
};

export default App;
