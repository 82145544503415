import React from "react";

const PrivacyPolicy = () => {
  return (
    <section className="static_page">
      <div className="container_width">
        <h1 style={{ textAlign: "start" }}>Privacy Policy</h1>
        <p>Updated 12/11/23</p>
        <h4>
          <b>About YOLOH Inc. and This Policy</b>
        </h4>
        <p>
          YOLOH Inc. is a technology-driven insurance marketplace, including
          public websites and mobile apps (collectively, “Platform”), for
          consumers and businesses to learn about and purchase various lines of
          insurance and warranty products, attempting to mitigate risk at an
          individual, household, and small business level.
        </p>
        <p>
          YOLOH Inc. and its affiliates, subsidiaries, and partners
          (collectively “YOLOH” or “we”, “us” and “our”) are committed to
          preserving your privacy. The purpose of this Policy is to explain how
          we collect, process, use, and disclose information gathered from your
          visit to, and/or use of, our Platform.
        </p>
        <p>
          This Policy applies to all Platform visitors where this Policy is
          displayed or linked.
        </p>
        <h4>
          <b>Consent</b>
        </h4>
        <p>
          By using our Platform, you consent to the conditions set forth in this
          Privacy Policy, providing the legal basis for YOLOH processing your
          personal data. You may withdraw your consent at any time. If you
          withdraw your consent, it will not make unlawful any processing which
          we completed before you withdraw your consent. You can withdraw your
          consent by writing to <span><a href="mailto:connect@yoloh.com">connect@yoloh.com</a></span>
        </p>
        <h4>
          <b>Personal Data Collected</b>
        </h4>
        <p>
          We collect data directly from our Platform visitors, as well as from
          third parties, where applicable.
        </p>
        <p>
          Our Platform is very role-based, meaning that depending upon the
          purpose of your visits to our Platform, we may gather different kinds
          of personal data. Consumers shopping for insurance, for example, will
          be asked for different data inputs than vendors, insurance companies,
          agents or brokers. Data you supply to our Platform will be stored and
          maintained indefinitely to foster a better experience when you begin a
          specific task, but you decide to finish it upon a subsequent visit, as
          an example. We may use collected information to display ads to you
          across multiple devices, such as your computer, smartphone or tablet,
          or to moderate how many times ads are presented. Our Platform may also
          help provide us with data and analytics in an attempt to deliver
          visitors a better experience, ad engagement and strategy or similar
          intelligence.e.
        </p>
        <p>
          When visitors are shopping for insurance, warranty or other products
          on our Platform, we will collect what we deem as pertinent data to
          best facilitate desirable quotes and outcomes. This will cause YOLOH
          to share such personal data with our business partners such as
          insurance companies, agents, brokers and similar entities – all of
          which are under formal contractual agreement with us. Data collected
          will include, but may not be limited to: name, address, phone number,
          email address, birth date, property and/or vehicle information,
          insurance claims, driving records, income or financial information,
          insurance carriers and more. You may even be asked to provide similar
          information on other household or business members. Data sought by
          YOLOH may vary depending upon the type of insurance or warranty or
          other products being considered. Data we collect from third parties
          may include the following methods (not all-inclusive):
        </p>
        <ul>
          <li>
            Personal Data – Names, addresses, birth date, age, phone numbers,
            email addresses, driver’s license or ID card information, insurance
            carrier data and records, employment, education and financial data…
            and more.
          </li>
          <li>
            Identification Characteristics – Legal names, maiden names, alias
            names, Social Security Numbers, USPS addresses, email addresses, IP
            addresses and activity, and other personal identification
            techniques.
          </li>
          <li>Employment & Education Data</li>
          <li>
            Available Internet Activity – Browsing, searching, ad engagement,
            social media accounts and more.
          </li>
          <li>
            Risk Modeling – Can include data from a wide variety of sources to
            include court and criminal data, health data, driving record and
            accident/claim history, driving behavior trackers, consumer and
            credit reporting agencies, business reporting agencies, and more.
          </li>
          <li>
            Shopping and purchase related data and preferences, location,
            geo-location, physical property and personal property records, and
            more.
          </li>
          <li>
            Health Information – Might include this content should you be
            seeking YOLOH support on health insurance options.
          </li>
          <li>
            Historical and Predictive Analytics – Increasingly, there are more
            and more ‘Big Data’ service providers that can supply consumer
            profiling data purporting preferences, attitudes, political and
            social information, biases and tendencies, psychological trending
            and more.
          </li>
        </ul>
        <h4>
          <b>Our Use of Your Personal Data</b>
        </h4>
        <p>
          Our commitment is to collect and use only that personal data that is
          needed to best facilitate your inquiries on our Platform so that YOLOH
          may deliver a memorable and best-in-class visitor experience.
        </p>
        <p>
          We only use personal data collected for YOLOH business purposes. YOLOH
          Inc. is a technology driven insurance marketplace, a Platform for
          consumers and small businesses to learn about and purchase various
          lines of insurance and warranty products. We collect related data to
          determine eligibility and/or customization for insurance, warranty and
          other products sold by us and/or our Business Partners (i.e.,
          insurance carriers, their agents and brokers, etc.). We may also use
          this personal data for:
        </p>
        <ul>
          <li>Visitor identification verification</li>
          <li>
            Strategic analysis on product offerings and service infrastructure
          </li>
          <li>
            Improving the visitor and business partner workflow and overall
            experience
          </li>
          <li>
            Risk mitigation, fraud detection and overall Platform security
          </li>
          <li>
            Monitoring YOLOH and business partner adherence to legal, regulatory
            and compliance requirements Your personal data, no matter how
            obtained, is consumed and used for the purpose of meeting and
            exceeding your needs. Because we leverage best-in-class technology
            and workflows to match your perceived insurance and risk mitigation
            needs with offers (i.e., quotes) from insurance producers and
            carriers, YOLOH is likely to be compensated in various ways. We may
            earn compensation from our Business Partners for leads or their
            responding with insurance quotes, and potentially if you (or other
            visitors) ultimately buy insurance, warranty or other products and
            services via navigation of our Platform.
          </li>
        </ul>
        <h4>
          <b>Do We Share Your Personal Data?</b>
        </h4>
        <p>
          As previously mentioned, personal data is used for YOLOH business
          purposes, primarily to best accommodate activity initiated by our
          visitors.
        </p>
        <p>
          When visitors make specific requests on our Platform, such as seeking
          quotes on insurance or warranty products, this usually necessitates
          sharing his/her personal data. Such data may be shared with a limited
          number of affiliated entities and non-affiliated third parties,
          including:
        </p>
        <ul>
          <li>YOLOH affiliates.</li>
          <li>
            Licensed insurance companies, agents and brokers, under formal
            contract with YOLOH (“Business Partners”)
          </li>
          <li>Insurance support organizations</li>
          <li>
            Only as legally mandated, government entities (e.g., regulatory,
            quasi-regulatory, tax or other authorities, law enforcement
            agencies, courts, arbitrational bodies, and fraud prevention
            agencies); List not all-inclusive; As permitted by law
          </li>
          <li>
            Companies that provide marketing services on our behalf or as part
            of a joint marketing agreement for products offered by YOLOH and/or
            Business Partners. We will not share your personal data with others
            for their own sales and marketing purposes.
          </li>
        </ul>
        <p>
          Depending upon how far you decide to navigate on our Platform, you are
          likely to interface directly with our Business Partners. They too may
          collect, use and retain your personal information even if you choose
          never to use their services. Our business model is very dependent upon
          an exchange of personal information between us and our Business
          Partners, whereby your information is often included.
        </p>
        <p>
          Know that our Business Partners have their own privacy policies, and
          you are encouraged to understand their data collection and management
          practices, as they may differ from YOLOH’s. For example, if upon
          visiting our Platform, you get a quote from an insurance company, they
          have attained enough personal information on you to build a quote, and
          therefore should also have a privacy policy for your viewing. Also
          know that we individually select Business Partners based upon prudent
          due diligence and we have contractual agreements. We aspire to only
          conduct business with the most reputable, sound and service-oriented
          partners.
        </p>
        <p>
          Beyond the mostly visitor driven transactions described herein, we
          also reserve the right to share relevant information as it relates to:
          Corporate transactions such as a sale, merger, divestiture or
          bankruptcy; Upholding contracts and policies; Investigation or
          prevention of illegal activities or perceived wrongdoing; Protecting
          the safety and rights of YOLOH business interests, visitors and
          employees;
        </p>
        <h4>
          <b>General Data Protection Regulation (GDPR)</b>
        </h4>
        <p>
          For users in the European Union, we adhere to the Regulation (EU)
          2016/679 of the European Parliament and of the Council of 27 April
          2016, known as the General Data Protection Regulation (the “GDPR”).
          For users in the United Kingdom, we adhere to the GDPR as enshrined in
          the Data Protection Act 2018.
        </p>
        <p>Legal Basis for Processing</p>
        <p>
          We collect and process personal data about users in the EU only when
          we have a legal basis for doing so under Article 6 of the GDPR. We
          rely on the following legal basis to collect and process the personal
          data of users in the EU:
        </p>
        <p>
          Users have provided their consent to the processing of their data for
          one or more specific purposes.
        </p>
        <p>International Data Transfers</p>
        <p>We transfer user personal data to the following countries:</p>
        <p>India.</p>
        <p>
          When we transfer user personal data, we will protect that data as
          described in this Privacy Policy and comply with applicable legal
          requirements for transferring personal data internationally. If you
          are located in the United Kingdom or the European Union, we will only
          transfer your personal data if:
        </p>
        <ul>
          <li>
            The country your personal data is being transferred to has been
            deemed to have adequate data protection by the European Commission,
            or if you are in the United Kingdom, by the United Kingdom adequacy
            regulations; or
          </li>
          <li>
            We have implemented appropriate safeguards in respect of the
            transfer. For example, the recipient is a party to binding corporate
            rules, or we have entered into standard EU or United Kingdom data
            protection contractual clauses with the recipient.
          </li>
        </ul>
        <p>Your Rights as a User</p>
        <p>Under the GDPR, you have the following rights:</p>
        <ul>
          <li>Right to be informed;</li>
          <li>Right of access;</li>
          <li>Right to rectification;</li>
          <li>Right to erasure;</li>
          <li>Right to restrict processing;</li>
          <li>Right to data portability; and</li>
          <li>Right to object.</li>
        </ul>
        <p>How to Access, Modify, Delete, or Challenge the Data Collected</p>
        <p>
          If you have any inquiries, questions or requests regarding collection,
          management, use, retention, deletion, modification or disclosure of
          your personal data, please contact <span><a href="mailto:connect@yoloh.com">connect@yoloh.com</a></span>
        </p>
        <p>
          You may use the aforementioned email to exercise any of your other
          rights under the GDPR.
        </p>
        <h4>
          <b>Protecting the Privacy of Children</b>
        </h4>
        <p>
          The YOLOH Platform is directed toward individuals 18 years or age and
          older. Therefore, we do not anticipate any interaction with children
          in any country. Should we discover that YOLOH has obtained personal
          data from a child, without the consent of a parent or guardian, we
          will delete that information as soon as possible. If you believe we
          have collected such information, please contact us at
          <span><a href="mailto:connect@yoloh.com">connect@yoloh.com</a></span>
        </p>
        <p>
          There are times when adult users, over the age of 18, might supply
          personal data of individuals under the age of 18 as part of applying
          for insurance and risk mitigation products available via YOLOH, such
          as health insurance, auto insurance and more (not all-inclusive). All
          such instances will be deemed as parental or guardian consent.
        </p>
        <p>
          Name, age and address are generally all that may be needed, collected
          via applying for such insurance and risk mitigation products on our
          Platform. Driving history may also be included, self-identified or
          pulled from our third parties. The purpose for all such personal
          information shall be solely for allowing for user-requested
          policy/coverage quotes.
        </p>
        <p>
          The only third-party disclosures of this personal data shall be to
          YOLOH product and service partners that we selectively engage in order
          to provide users with price and policy quotes.
        </p>
        <p>Parental Rights</p>
        <p>
          A child’s parent or guardian can review their child’s personal
          information and direct us on desired action. A parent or guardian can
          also agree to the collection and use of their child’s personal
          information but not allow us to disclose that personal information to
          third parties, unless disclosure is part of the service provided by
          our Site.
        </p>
        <p>
          A parent or guardian can exercise these rights be communicating with
          YOLOH via the methods within the ‘How to Contact Us’ section at the
          end of this Privacy Policy.
        </p>
        <p>
          This policy complies with the U.S. Children’s Online Privacy
          Protection Act (15 U.S.C. §§ 6501-6506)
        </p>
        <h4>
          <b>Cookie Policy</b>
        </h4>
        <p>
          About cookies, in general. HTTP cookies (also called web cookies,
          Internet cookies, browser cookies, or simply cookies) are small blocks
          of data created by a web server while a user is browsing a website and
          placed on the user’s computer (or other device) by the user’s web
          browser. More than one cookie may be placed on a user’s device during
          a session. In short, cookies are used to track specific navigation of
          a website to provide intelligence to the website owner. They can also
          be used to save data for subsequent use information that the user
          previously entered into form fields, like names, addresses, passwords,
          payment card numbers and more.
        </p>
        <p>
          We may use IP addresses to learn other information concerning Platform
          visitors as described in this policy, but we do not expect to learn
          the identity of the individual visitors. There are times when we may
          attempt to identify your browser and combine information from cookies
          and stored data, such as when you select a product, fill in forms or
          complete a survey. You will have a choice whether or not to accept the
          cookie. If you reject the cookie, or if cookies are disabled on your
          web browser, some functions of the YOLOH Platform may not work as
          intended.
        </p>
        <p>
          YOLOH may use pixels tags, transparent GIF files and third-party
          vendors to help manage our online advertising and promotions. These
          tags collect anonymous information, not personally identifiable, to
          help analyze details about ads and promotions within the Platform. The
          tags enable us to recognize a unique cookie on your Web browser, which
          in turn enables us to learn how users respond to our online ads and
          promotions. With both cookie and tag technology, the information that
          we collect and share is anonymous and not personally identifiable. It
          does not contain your name, address, telephone number or email
          address.
        </p>
        <h4>
          <b>Key Disclosures</b>
        </h4>
        <p>
          <b> Behavioral Advertising Compliance Disclosure:</b> YOLOH may use
          data collected on our Platform to customize ads to you on other
          Websites and Mobile Applications. If you do not want your browsing
          behavior used for online behavioral advertising purposes, opt-out from
          YOLOH by contacting us at <span><a href="mailto:connect@yoloh.com">connect@yoloh.com</a></span>
        </p>
        <p>
          <b>DAA Compliance Disclosure:</b> YOLOH uses a service to comply with
          the cross-industry Self-Regulatory Program for Online Behavioral
          Advertising managed by the Digital Advertising Alliance (DAA)
          (http://www.aboutads.info). As part of this service, YOLOH’s digital
          advertisements are sometimes delivered with icons that help consumers
          understand how their data is being used and provide choice options to
          consumers that want more control.
        </p>
        <p>
          <b>Non-YOLOH Websites and Mobile Applications: </b> YOLOH is not
          responsible for the privacy practices or the content of any other
          websites and mobile applications on the Internet that may be linked to
          the YOLOH Platform. Please note that when you click on links to
          websites and mobile applications outside of YOLOH, you are transferred
          to another website or mobile application that may not be under YOLOH’s
          control. We encourage you to read the privacy statements of these
          linked sites and mobile applications as their privacy policy may
          differ from ours.
        </p>
        <p>
          <b>Changes to this Policy:</b> We reserve the right to update this
          Privacy Policy at any time. Changes to be posted herein.
        </p>
        <h4>
          <b>California Residents – Info Sharing Disclosure</b>
        </h4>
        <p>
          California residents have certain rights with respect to your personal
          information under the California Consumer Privacy Act of 2018, as
          amended (the “CCPA”). There are some additional state and federal
          rules and regulations, including the Gramm-Leach Bliley Act (GLBA),
          the California Financial Information Privacy Act (“FIPA”) as well as
          certain federal and California health privacy laws that cover the
          information you likely provided in utilizing our Platform.
        </p>
        <p>
          California residents having a business relationship with us have
          options on how we manage your data. If you wish to opt out of our own
          direct marketing efforts, or have any related requests under CCPA,
          please write to us at:
        </p>
        <p>
          Privacy Policy Administrator <br />
          6660 N. High St., Suite 3-H <br />
          Worthington, Ohio 43085
        </p>
        <p>All requests must state:</p>
        <ul>
          <li>The specific desired action you want us to take under CCPA</li>
          <li>Your full name and address we have on file</li>
          <li>Phone number and date of birth</li>
        </ul>
        <h4>
          <b>How to Contact Us:</b>
        </h4>
        <p>
          To request additional information, or obtain a copy of our Privacy
          Policy in an alternative format, please write us at::
        </p>
        <p>
          Privacy Policy Administrator <br />
          6660 N. High St., Suite 3-H <br />
          Worthington, Ohio 43085
        </p>
        <h4>
          <b><a href="mailto:connect@yoloh.com">connect@yoloh.com</a></b>
        </h4>
      </div>
    </section>
  );
};

export default PrivacyPolicy;
