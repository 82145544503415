export const handleMobileValidate = (value) => {
  const TEN_DIGIT_PHONE_REGEX = new RegExp(`^[0-9]{10}$`);
  // const updated = value.replace("+1", "").replace("+91", "")
  // && (value.indexOf("+1") > -1 || value.indexOf("+91") > -1)
  return TEN_DIGIT_PHONE_REGEX.test(value) ;
}

export const handleMobileValidateForProfile = (value) => {
  const updated = value.replace("+1", "").replace("+91", "")
  const TEN_DIGIT_PHONE_REGEX = new RegExp(`^[0-9]{10}$`);
  return TEN_DIGIT_PHONE_REGEX.test(updated) && (value.indexOf("+1") > -1 || value.indexOf("+91") > -1 || value.indexOf("") > -1) ;
}
export const handleEmailValidate = (value) => {
  const EMAIL_REGEX = new RegExp(`^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$`);
  return EMAIL_REGEX.test(value);
}
export const handleInputBoxSpaceValidate = (value) => {
  const INPUT_BOX = value?.trim()
  return INPUT_BOX?.length > 0 ? null : "space only is not allowed"
}

export const handleDynamicValidate = (value, regexp) => {
  const EMAIL_REGEX = new RegExp(regexp);
  return EMAIL_REGEX.test(value);
}

export const handleCommaSeparatedValue  = (value) => {
 return  value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}