import { myAxios } from "../axios";
import { environment } from "../../environment/environment";

// 2 possible values [step2 & step3]
// This is will decide that
// which address will be sent to server upon creation of lead.
export const currentActivatedAddressForHomeInsurance = "step2";

export const getLeadList = (payload) => {
  return myAxios.post(environment.api_endpoint + "/agents", payload);
};

export const storeOrUpdateLead = (payload) => {
  return myAxios.post(environment.lead_api_endpoint, payload);
};

export const saveLeadAsDraft = (payload) => {
  return myAxios.post(environment.lead_api_endpoint, payload);
};

export const getLeadDetails = (payload) => {
  return myAxios.post(environment.api_endpoint + "/agents", payload);
};

export const getInsurances = (payload) => {
  return myAxios.post(environment.api_endpoint + "/agents", payload);
};
export const getInsuranceLists = (payload) => {
  return myAxios.post(environment.api_endpoint + "/insurance", payload);
};
export const insurancesOption = (payload) => {
  return myAxios.post(environment.api_endpoint + "/agents", payload);
};
export const vaultPin = (payload) => {
  return myAxios.post(environment.api_endpoint + "/vault-pin", payload);
};
export const getNotification = (payload) => {
  return myAxios.post(environment.api_endpoint + "/users", payload);
};
export const getPolicyLists = (payload) => {
  return myAxios.post(environment.api_endpoint + "/insurance", payload);
};

export const insuranceVerification = (payload) => {
  return myAxios.post(
    environment.api_endpoint + "/insurance-verification",
    payload
  );
};
export const insurancePreFillLead = (payload) => {
  return myAxios.post(environment.api_endpoint + "/agents", payload);
};
export const getPolicyIdCardLists = (payload) => {
  return myAxios.post(environment.api_endpoint + "/id-cards", payload);
};
export const canopyLoginExternalSource = (payload) => {
  return myAxios.post(environment.api_endpoint + "/external-source", payload);
};

export const getUserLeadUUID = (payload) => {
  return myAxios.post(environment.api_open_endpoint + "/lender", payload);
};
export const openApiEndPoint = (payload) => {
  return myAxios.post(environment.api_open_endpoint + "/open-api", payload);
};

export const contactLeadEndPoint = (payload) => {
  return myAxios.post(environment.api_endpoint + "/contact-lead", payload);
};
