import { responsiveFontSizes } from "@mui/material/styles";
import { createTheme } from "@mui/material/styles";
const theme = responsiveFontSizes(
  createTheme({
    spacing: 4,
    typography: {
      fontFamily: "Jokker-Regular",
      h1: {
        fontSize: "5rem",
        fontWeight: 700,
      },
      h2: {
        fontSize: "3.5rem",
        fontStyle: "bold",
        fontWeight: 600,
      },
      h3: {
        fontSize: "2.5rem",
        fontWeight: 500,
      },
    },
    palette: {
      background: {
        default: "#FFEFE8", //lightplaster
      },
      primary: {
        main: "#09143A", //darkblue
      },
      secondary: {
        main: "#09143A", //yellow 
      },
      error: {
        main: "#F06363", //red
      },
      danger: {
        main: "#FF0000", //red
      },
      warning: {
        main: "#FFEFE8", //lightplaster
      },
      info: {
        main: "#6B7D6A", //gray
      },
      success: {
        main: "#54C192", //green
      },
      backgroundWhite: {
        main: "#FFEFE8", //white
      },
      turquoise: {
        main: "#30ebf2", //turquoise
      },
      iconSecondary:{
        main: "#FFCD00", //yellow
      },
      white:{
        main : "#FFFFFF" ,//white
        focus : "#FFCD00",
        state : "#54C192"
      },
      text: {
        primary: "#09143A", //darkblue
        secondary: "#838190", //lightblue
        custom: "#fff", //white
      },
    },
  })
);

export default theme;
