import React, { useContext, useEffect, useRef, useState } from "react";
import "./index.css";
import logoNew from "../assets/images/logoNew.png";

import yolohLogo from "../assets/images/logo.svg";
import user from "../assets/images/user.png";
import down from "../assets/images/down.svg";
import backArrow from "../assets/images/header_back_arrow.svg";
import { ReactComponent as NotificationIcon } from "../assets/images/Notification_darkblue.svg";
import { ReactComponent as NotificationRadDot } from "../assets/images/Notification_darkblue_red_dot.svg";
import { Badge, Container } from "@mui/material";
import MenuDrawer from "./MenuDrawer";
import Modal from "@mui/material/Modal";
import Login from "./Login";
import { useDispatch } from "react-redux";
import { users } from "../redux/slices/authReducer";
import EditIcon from "@mui/icons-material/Edit";
import homeIns from "../assets/images/Home-ins.svg";
import floodIns from "../assets/images/Flood-ins.svg";
import { useLocation, useNavigate } from "react-router";
import { NavLink } from "react-router-dom";
import useOnClickOutside from "../hooks/useOnClickOutside";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import Avatar from "@mui/material/Avatar";
import ListItemIcon from "@mui/material/ListItemIcon";
import { Logout, Settings } from "@mui/icons-material";
import { useAuth } from "../context/AuthContext";
import { useLocalStorage } from "../http/services/local-storage";
import { Link } from "react-scroll";
import Insurances from "../pages/insurances";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { getDashboardStatsPayloadTemplate } from "../http/services/api-payload-prepare";
import { dashboardStatsApi } from "../http/services/quote.service";
import NotificationContext from "../context/NotificationContext";
import SwipeableTemporaryDrawer from "./ResponsiveHeader";
import HelpDielog from "./HelpDielog";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";

export const Header = ({ isLayout, isPrivate, isView, isHeader = true }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { logout } = useAuth();
  const { getItem } = useLocalStorage();
  const [isShown, setIsShown] = useState(false);
  const { notificationCount, setNotificationCount } =
    useContext(NotificationContext);
  const [open, setOpen] = useState(false);
  // const [notificationCount, setNotificationCount] = useState(0);
  // const [anchorEl, setAnchorEl] = useState(null);
  const [loginModal, setLoginModal] = useState(false);
  const [helpModal, setHelpModal] = useState(false);
  const closeHelpModel = () => {
    setHelpModal(false);
  };
  // const auth = useSelector((state) => state.counter.value)
  const dispatch = useDispatch();
  // const open = Boolean(anchorEl);

  // const handleClose = () => {
  //   setAnchorEl(null);
  // };
  useState(() => {
    const screenPath = location?.pathname?.split("/");
    if (screenPath[1] === "lender_user" || screenPath[1] === "ivs") {
      setLoginModal(true);
    }
  }, [location?.pathname]);
  useEffect(() => {
    if (isHeader && isPrivate) {
      getNotificationCount();
    }
  }, []);
  const handleClick = (event) => {
    setIsShown((current) => !current);
  };
  const openLoginModal = () => {
    setLoginModal(true);
  };
  const closeLoginModal = () => {
    setLoginModal(false);
  };
  const onLogin = () => {
    dispatch(users({ userName: "auth" }));
  };
  const ref = useRef();
  useOnClickOutside(ref, () => (isShown ? setIsShown(false) : null));
  const [width, setWidth] = useState(0);
  const [anchorEl, setAnchorEl] = React.useState(null);
  // const open = Boolean(anchorEl);
  const handleProfileMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    logout();
    navigate("/");
    setOpen(false);
  };

  const { authUser, setAuthUser, isLoggedIn } = useAuth();
  const [accountCreated, setAccountCreated] = useState(false);
  const [selectedContain, setSelectedContain] = useState(null);
  const [profileData, setProfileData] = useState(false);
  useEffect(() => {
    if (getItem("authUserValidated") === "true") {
      setAccountCreated(true);
      if (getItem("authUserProfile") !== "undefined") {
        setProfileData(JSON.parse(getItem("authUserProfile")));
      }
    }
    // else if (location && location.search && location.search.replace("?", "") === 'login') {
    //   setLoginModal(true)
    // }
  }, [authUser]);
  useEffect(() => {
    if (location?.hash) {
      scrollToDiv(location?.hash);
    }
  }, [location?.hash]);
  const scrollToDiv = (id) => {
    const element = document.getElementById(id.replace("#", ""));
    setSelectedContain(id);
    // if (element?.offsetLeft && element?.offsetTop) {
    window.scrollTo({
      left: element?.offsetLeft,
      top: element?.offsetTop,
      behavior: "smooth",
    });
    // }
  };
  const scrollHeight = () => {
    var el = document.documentElement,
      ScrollTop = el.scrollTop || document.body.scrollTop,
      ScrollHeight = el.scrollHeight || document.body.scrollHeight;
    var percent = (ScrollTop / (ScrollHeight - el.clientHeight)) * 100;
    setWidth(percent);
  };
  useEffect(() => {
    window.addEventListener("scroll", scrollHeight);
    return () => window.removeEventListener("scroll", scrollHeight);
  });
  const getNotificationCount = async () => {
    const dashboardPayload = getDashboardStatsPayloadTemplate();
    const data = await dashboardStatsApi(dashboardPayload);
    if (data && data.code === 201 && data.userDashboardStats) {
      setNotificationCount(data.userDashboardStats?.notification_count);
    }
  };

  // const [visibleId, setVisibleId] = useState(null);

  // useEffect(() => {
  //   const handleScroll = () => {
  //     const elements = document.querySelectorAll('.scroll-item');
  //     let foundId = null;

  //     elements.forEach((element) => {
  //       const rect = element.getBoundingClientRect();
  //       if (rect.top >= 0 && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)) {
  //         foundId = element.id;
  //       }
  //     });

  //     setVisibleId(foundId);
  //   };

  //   window.addEventListener('scroll', handleScroll);
  //   return () => window.removeEventListener('scroll', handleScroll);
  // }, []);

  // console.log(visibleId,"visibleIdvisibleId")

  const [scrollPosition, setScrollPosition] = useState(0);
  const [documentHeight, setDocumentHeight] = useState(0);
  const [scrollDirection, setScrollDirection] = useState(null); // 'up' or 'down'

  // Reference to store the previous scroll position
  const prevScrollY = useRef(0);

  // Function to handle the scroll event
  const handleScroll = () => {
    const currentScrollY = window.scrollY;

    // Update scroll position and document height
    setScrollPosition(currentScrollY);
    setDocumentHeight(document.documentElement.scrollHeight);

    // Determine scroll direction
    if (currentScrollY > prevScrollY.current) {
      setScrollDirection("down");
    } else if (currentScrollY < prevScrollY.current) {
      setScrollDirection("up");
    } else {
      setScrollDirection(null); // No scroll or minor scroll
    }

    // Update previous scroll position
    prevScrollY.current = currentScrollY;
  };

  // Set up the scroll event listener on component mount
  useEffect(() => {
    // Update the height and direction initially
    handleScroll();

    // Add the scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      {!isPrivate && (
        <div
          className="header-progress-bar"
          style={{ width: width + "%" }}
        ></div>
      )}
      <div
        className={
          scrollPosition > 0 && scrollDirection === "down"
            ? "mainHeader mainHeaderScroll"
            : "mainHeader"
        }
      >
        <div className="container_width header_container">
          <div className="toolbar" role="banner">
            <img
              width="136px"
              alt="Angular Logo"
              src={logoNew}
              className="logoMain"
              onClick={() => navigate("/")}
            />
            <div className="headerMenu">
              {/* {isPrivate ? (
                <>
                  <li>
                    <NavLink to="/overview/">Dashboard</NavLink>
                  </li>
                  <li onClick={() => setInsuranceModel(true)}>
                    <NavLink>Request Quotes</NavLink>
                  </li>
                  <NavLink
                    to="/activity"
                    className="d-flex align-items-center gap-10"
                  >
                    Activity
                  </NavLink>
                  <NavLink
                    to="/help"
                    className="d-flex align-items-center gap-10"
                  >
                    Help
                  </NavLink>
                </>
              ) : (
                <> */}

              <div className="headerLink">
                <>
                  {" "}
                  <li>
                    <NavLink
                      onClick={() => scrollToDiv("about")}
                      to="/#about"
                      className={`${
                        selectedContain === "about" ? "selected-tab" : ""
                      }`}
                    >
                      About
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      onClick={() => scrollToDiv("service")}
                      to="/#service"
                      className={`${
                        selectedContain === "service" ? "selected-tab" : ""
                      }`}
                    >
                      Services
                    </NavLink>
                  </li>
                  {
                    !(
                      (
                        localStorage.getItem("authUser") &&
                        localStorage.getItem("authUserProfile")
                      )
                      // ) && (
                      //   <li>
                      //     <NavLink
                      //       onClick={() =>
                      //         isPrivate
                      //           ? navigate("/insurances")
                      //           : scrollToDiv("insurance-marketplace")
                      //       }
                      //       to={
                      //         isPrivate ? "/insurances" : "/#insurance-marketplace"
                      //       }
                      //       className={`${
                      //         selectedContain === "insurance-marketplace"
                      //           ? "selected-tab"
                      //           : ""
                      //       }`}
                      //     >
                      //       Insurance Marketplace
                      //     </NavLink>
                      //   </li>
                    )
                  }
                  <li>
                    <NavLink
                      to="/#join-us"
                      onClick={() => scrollToDiv("join-us")}
                      className={`${
                        selectedContain === "join-us" ? "selected-tab" : ""
                      }`}
                    >
                      Join Us
                    </NavLink>
                  </li>
                  {localStorage.getItem("authUser") &&
                  localStorage.getItem("authUserProfile") ? (
                    <li>
                      <NavLink
                        // to="/help"
                        onClick={() => setHelpModal(true)}
                        // className={`${
                        //   selectedContain === "contact-us" ? "selected-tab" : ""
                        // }`}
                      >
                        Help
                      </NavLink>
                    </li>
                  ) : (
                    <li>
                      <NavLink
                        to="/#contact-us"
                        onClick={() => scrollToDiv("contact-us")}
                        className={`${
                          selectedContain === "contact-us" ? "selected-tab" : ""
                        }`}
                      >
                        Help
                      </NavLink>
                    </li>
                  )}
                </>
                {localStorage.getItem("authUser") &&
                localStorage.getItem("authUserProfile") ? (
                  accountCreated && (
                    <div
                      className="userInfo header-avatar"
                      onClick={() => setOpen(true)}
                    >
                      <h4 className="profileUserName">
                        {`Hi, ${
                          authUser?.authUserProfile?.first_name.split(" ")
                            ?.length > 0 &&
                          authUser?.authUserProfile?.first_name
                            .split(" ")[0]
                            ?.slice(0, 10)
                        } ${authUser?.authUserProfile?.last_name?.slice(
                          0,
                          1
                        )}.`}
                      </h4>
                      <div className="userImg avatarUser">{`${authUser?.authUserProfile?.first_name?.charAt(
                        0
                      )}${authUser?.authUserProfile?.last_name?.charAt(
                        0
                      )}`}</div>
                      <img src={backArrow} alt="back-arrow" />
                    </div>
                  )
                ) : (
                  <>
                    <a
                      className="loginBtn loginBtnHeader"
                      onClick={() => openLoginModal(!loginModal)}
                    >
                      Log in / Sign up
                    </a>
                  </>
                )}
              </div>
              <SwipeableTemporaryDrawer
                setLoginModal={setLoginModal}
                isPrivate={isPrivate}
                isLayout={isLayout}
                authUser={authUser}
                closeLoginModal={closeLoginModal}
                open={open}
                setOpen={setOpen}
                handleLogout={handleLogout}
                scrollToDiv={scrollToDiv}
                openLoginModal={openLoginModal}
                loginModal={loginModal}
                accountCreated={accountCreated}
                setHelpModal={setHelpModal}
              />
              {/* <li>
                    <Link
                      to="about"
                      spy={true}
                      smooth={true}
                      style={{ cursor: "pointer" }}
                    >
                      About Us
                    </Link>
                  </li> */}
              {/* <li>
                    <NavLink to="/product">Products</NavLink>
                  </li> */}
              {/* </>
              )} */}
            </div>

            <Modal
              keepMounted={false}
              open={loginModal}
              // onClose={closeLoginModal}
              aria-labelledby="keep-mounted-modal-title"
              aria-describedby="keep-mounted-modal-description"
            >
              <Box>
                <Login setLoginModal={setLoginModal} />
              </Box>
            </Modal>

            <Modal
              keepMounted={false}
              open={helpModal}
              // onClose={closeLoginModal}
              aria-labelledby="keep-mounted-modal-title"
              aria-describedby="keep-mounted-modal-description"
            >
              <Box>
                <HelpDielog
                  onClose={closeHelpModel}
                  profileDetails={authUser?.authUserProfile}
                />
              </Box>
            </Modal>
          </div>
          {open && (
            <MenuDrawer
              setLoginModal={setLoginModal}
              isPrivate={isPrivate}
              isLayout={isLayout}
              authUser={authUser}
              closeLoginModal={closeLoginModal}
              open={open}
              setOpen={setOpen}
              handleLogout={handleLogout}
              scrollToDiv={scrollToDiv}
              openLoginModal={openLoginModal}
              loginModal={loginModal}
              setHelpModal={setHelpModal}
            />
          )}
        </div>
      </div>
      {isHeader && isPrivate && (
        <>
          <div className="secondHeader">
            <div className="container_width">
              <div className="secondHeaderItem">
                <div className="secondUserIcon avatarUser">{`${authUser?.authUserProfile?.first_name?.charAt(
                  0
                )}${authUser?.authUserProfile?.last_name?.charAt(0)}`}</div>
                <h4 className="profileUserName">
                  {`${
                    authUser?.authUserProfile?.first_name.split(" ")?.length >
                      0 &&
                    authUser?.authUserProfile?.first_name
                      .split(" ")[0]
                      ?.slice(0, 10)
                  } ${authUser?.authUserProfile?.last_name?.slice(0, 1)}.`}
                </h4>
                <div className="cursor-pointer">
                  <Badge
                    badgeContent={notificationCount}
                    color="iconSecondary"
                    onClick={() => navigate("/notification")}
                  >
                    <NotificationsIcon />
                  </Badge>
                </div>
              </div>
            </div>
          </div>
          {/* thirdHeaderMain */}
          <div
            className={
              scrollPosition > 0 && scrollDirection === "down"
                ? "thirdHeaderMain mainHeaderScroll"
                : "thirdHeaderMain"
            }
          >
            <div className="container_width">
              <div className="thirdHeader">
                <NavLink to="/overview">Overview</NavLink>

                {/* <NavLink to="/activity">Activity</NavLink> */}

                <NavLink to="/insurances">Insurance Marketplace</NavLink>

                <NavLink to="/policy-vault">Vault</NavLink>

                {/* <Badge
                  className={`${
                    location?.pathname === "/notification"
                      ? "activeNotification"
                      : ""
                  } notificationalert`}
                  badgeContent={notificationCount}
                  color="danger"
                  onClick={() => navigate("/notification")}
                >
                  <NotificationsIcon color="iconSecondary" />
                </Badge> */}
                <NavLink to="/notification">
                  {notificationCount > 0 ? (
                    <div className="notification-menu">
                      <div className="notification-red-dot"></div>
                      <NotificationsNoneOutlinedIcon fontSize="medium" />
                    </div>
                  ) : (
                    <NotificationsNoneOutlinedIcon fontSize="medium" />
                  )}
                </NavLink>
                {/* <NavLink to="/insurance-verification-summary">
                  Insurance Verification Summary
                </NavLink> */}
                {/* <NavLink to="/profile">My Profile</NavLink> */}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
