import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoader: false,
};

export const loaderReducer = createSlice({
  name: "loader",
  initialState,
  reducers: {
    loaderStart: (state, action) => {
      state.isLoader = true;
    },
    loaderEnd: (state, action) => {
      state.isLoader = false;
    },
  },
});

export const { loaderStart, loaderEnd } = loaderReducer.actions;

export default loaderReducer.reducer;
