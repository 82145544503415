import React from "react";
import noRecordFound from "../assets/images/not-found.svg";
const NotFoundRecord = ({title, explore= false}) => {
  return (
    <div className="d-block m-auto">
      <div className="notFoundContain">
        <img
          src={noRecordFound}
          alt="noRecordFound"
          // width="579px"
          // height="300px"
        />
        <p>{title}</p>
        {explore && <button className="exploreBtn">Explore</button>}
      </div>
    </div>
  );
};

export default NotFoundRecord;
