import { configureStore } from "@reduxjs/toolkit";
import initialReducer from "./slices/intial";
import authReducer from "./slices/authReducer";
import loader from "./slices/loader";

export default configureStore({
  reducer: {
    initialState: initialReducer,
    auth: authReducer,
    loader: loader,
  },
});
