import { useEffect } from "react";
import AxiosInterceptorContext from "./AxiosInterceptorContext";
import axios from "axios";
import React from "react";
import { useAuth } from "../AuthContext";
import { loaderEnd } from "../../redux/slices/loader";
import { useDispatch } from "react-redux";

export const AxiosInterceptorContextProvider = ({ children }) => {
  const { logout } = useAuth();
  const dispatch = useDispatch();
  useEffect(() => {
    const resInterceptor = (response) => {
      return response;
    };
    const errInterceptor = (error) => {
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        logout("Token expired!", "warning");
        dispatch(loaderEnd());
      }
      return Promise.reject(error);
    };
    const interceptor = axios.interceptors.response.use(
      resInterceptor,
      errInterceptor
    );
    return () => axios.interceptors.response.eject(interceptor);
  }, []);
  return (
    <AxiosInterceptorContext.Provider value={"AXIOS"}>
      {children}
    </AxiosInterceptorContext.Provider>
  );
};

export default axios;
