import axios from "axios";

export const fetchGooglePlaces = async (searchText) => {
    const instance = axios.create();
    const url = process.env.REACT_APP_GOOGLE_MAP_ENDPOINT;
    const final = `${encodeURIComponent(url)}`;
    const response = await fetch(
    url,
        {
            method: "POST",
            headers: {},
            body: JSON.stringify({
                "query": searchText,
                "placeId": ""
            }),
        }
    );
    // let response = await instance.post(url, {
    //     "query": searchText,
    //     "placeId": ""
    // }).catch(() => {
    //     return [];
    // });
    if (response?.status === 200) {

        const responseData = await response.json();
        if (responseData.predictions && responseData?.predictions?.length > 0) {
            return responseData.predictions;
        } else {
            return [];
        }
    } else {
        return [];
    }
};

export const fetchGooglePlaceDetails = async (place_id) => {
    const instance = axios.create();
    const url = process.env.REACT_APP_GOOGLE_MAP_ENDPOINT;
    // const url = `https://maps.googleapis.com/maps/api/place/details/json?place_id=${place_id}&key=${process.env.REACT_APP_GOOGLE_MAP}`;
    const final = `${encodeURIComponent(url)}`;
    // let response = await instance.get(url).catch(() => {
    //     return null;
    // });
    const response = await fetch(
        url,
            {
                method: "POST",
                headers: {},
                body: JSON.stringify({
                    "query": '',
                    "placeId": place_id
                }),
            }
        );
    let placeData = {
        address: "",
        zip: "",
        state: "",
        city: "",
        country: "",
    };
    if (response?.status === 200) {
        const responseData = await response.json();
        // response = JSON.parse(response.data.contents);
        if (responseData && responseData?.result) {
            placeData.address = responseData.result.formatted_address;
            for (let item of responseData?.result.address_components) {
                if (item.types[0] === "postal_code") {
                    placeData.zip = item.long_name;
                }
                if (item.types[0] === "country") {
                    placeData.country = item.long_name;
                }
                if (item.types[0] === "administrative_area_level_1") {
                    placeData.state = item.long_name;
                }
                if (item.types[0] === "locality") {
                    placeData.city = item.long_name;
                }
            }
            return placeData;
        }
    }

    return placeData;
};
