import { Suspense, React, useContext, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { routes } from "./Routes";
import PrivateRoute from "./PrivateRoute";
import { Header } from "../comman/Header";
import Loader from "../comman/Loader";
import LoaderContext from "../context/LoaderContext";
import { Navigate, useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import Footer from "../comman/Footer";
import Lending from "../pages/Landing";
import CreateAccount from "../pages/CreateAccount";
import { useSelector } from "react-redux";
import { useAuth } from "../context/AuthContext";

const AppRoutes = () => {
  const isLoader = useSelector((state) => state?.loader?.isLoader);
  const location = useLocation();
  const navigation = useNavigate()
  // if (
  //   localStorage.getItem("authUser") &&
  //   localStorage.getItem("authUserValidated") === "false"
  //   //  &&
  //   // location.pathname !== "/create-account"
  // ) {
  //   return <CreateAccount />;
  //   // <Navigate to="/create-account" />;
  // }
  const { isLoggedIn } = useAuth();
  useEffect(() => {
    if (isLoggedIn()) {
      if (localStorage.hasOwnProperty("authUserValidated")) {
        if (localStorage.getItem("authUserValidated") === "false") {
          navigation("/")
        }
      }
    }
  }, []);
  return (
    <>
      {isLoader && <Loader loaderTransform="loaderTransform" />}
      <Suspense fallback={<Loader loaderTransform="loaderTransform" />}>
        <Routes>
          {routes &&
            routes.length > 0 &&
            routes.map((route, index) => (
              <Route
                path={route?.path}
                key={"route_" + index}
                element={
                  route.isPrivate ? (
                    <div className="main-container">
                      {/* {route?.path !== "/create-account" && ( */}
                      <Header
                        isLayout={route.isLayout}
                        isPrivate={route.isPrivate}
                        isView={route.isView}
                        isHeader={route?.isHeader}
                      />
                      {/* )} */}
                      <PrivateRoute isLayout={route.isLayout}>
                        <route.element />
                      </PrivateRoute>
                      {/* {route?.isFooter && <Footer />} */}
                    </div>
                  ) : (
                    <>
                      <Header
                        isLayout={route.isLayout}
                        isPrivate={route.isPrivate}
                        isView={route.isView}
                      />
                      <route.element />
                      <Footer isPrivate={route.isPrivate} />
                    </>
                  )
                }
              />
            ))}
          <Route
            path="/"
            element={
              <>
                <Header />
                <Lending />
                <Footer />
              </>
            }
          />
        </Routes>
      </Suspense>
    </>
  );
};

export default AppRoutes;
